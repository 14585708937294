import "./App.css";
import { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TrakIcon from "./assets/app-name-light.png";
const HomePage = lazy(() => import("./views/HomePage"));
const DownloadPage = lazy(() => import("./views/DownloadPage"));
const AboutPage = lazy(() => import("./views/AboutPage"));
const ContactPage = lazy(() => import("./views/ContactPage"));
const ActivityRedirectPage = lazy(() => import("./views/ActivityRedirectPage"));
const JoinGroupPage = lazy(() => import("./views/JoinGroupPage"));
const ConstructionPage = lazy(() => import("./views/ConstructionPage"));
const TermsPage = lazy(() => import("./views/TermsPage"));
const VerifyEmail = lazy(() => import("./views/VerifyEmail"));
const AdminPage = lazy(() => import("./views/AdminPage"));
const ClubSchemePage = lazy(() => import("./views/ClubSchemePage"));
const AdminMap = lazy(() => import("./views/AdminMap"));
const TutorialPage = lazy(() => import("./views/TutorialPage"));
const UserRedirectPage = lazy(() => import("./views/UserRedirectPage"));

const inConstruction = false;
const isBetaActive = false;
const isFBAvailable = true;

//VERSION 1.1.2 b 6

function App() {
  const [phoneMenuVisible, setphoneMenuVisible] = useState(false);
  const [language, setlanguage] = useState("fr");
  const [page, setpage] = useState("/");
  const availableLanguages = [
    { value: "fr", title: "Français", titleShort: "fr" },
    { value: "en", title: "English", titleShort: "en" },
    { value: "de", title: "Deutsch", titleShort: "de" },
    { value: "it", title: "Italiano", titleShort: "it" },
  ];

  const menuTitles = {
    contact: {
      fr: "Contact",
      en: "Contact",
      de: "Kontakt",
      it: "Contatto",
    },
    download: {
      fr: "Télécharger",
      en: "Download",
      de: "Herunterladen",
      it: "Scaricare",
    },
    about: {
      fr: "À propos",
      en: "About",
      de: "Über uns",
      it: "Su di noi",
    },
    app: {
      fr: "L'app",
      en: "The app",
      de: "Die App",
      it: "L'app",
    },
    club: {
      fr: "Clubs",
      en: "Clubs",
      de: "Vereine",
      it: "Clubs",
    },
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#151B1F";
  }, []);

  return (
    <Router>
      <div className="header-main-container">
        <div className="header-container">
          <Link
            className="header-item"
            to="/"
            onClick={() => {
              setphoneMenuVisible(false);
              setpage("/");
            }}
          >
            <img src={TrakIcon} alt="Trak icon" />
          </Link>
          {!inConstruction && (
            <div
              className="burger-menu"
              onClick={() => {
                setphoneMenuVisible(!phoneMenuVisible);
              }}
            >
              <div
                className={`burger-stroke burger-stroke-1 ${
                  phoneMenuVisible ? "open" : ""
                }`}
              />
              <div
                className={`burger-stroke burger-stroke-2 ${
                  phoneMenuVisible ? "open" : ""
                }`}
              />
            </div>
          )}
          {inConstruction && <div className="burger-menu"></div>}
          {!inConstruction && (
            <div className={`header-menu-container-desktop`}>
              <div className="languages-main-container-desktop">
                {availableLanguages.map((v, i) => {
                  return (
                    <div
                      key={`language_item_${i}`}
                      className={`languages-item${
                        language === v.value ? " selected" : ""
                      }`}
                      onClick={() => {
                        setlanguage(v.value);
                        setphoneMenuVisible(false);
                      }}
                    >
                      <h4>{v.titleShort}</h4>
                    </div>
                  );
                })}
              </div>
              <Link
                className={`header-menu-item${
                  page === "/telecharger" ? " selected" : ""
                }`}
                to="/telecharger"
                onClick={() => setpage("/telecharger")}
              >
                <h1>{menuTitles.download[language]}</h1>
              </Link>
              <Link
                className={`header-menu-item${
                  page === "/contact" ? " selected" : ""
                }`}
                to="/contact"
                onClick={() => setpage("/contact")}
              >
                <h1>{menuTitles.contact[language]}</h1>
              </Link>
              <Link
                className={`header-menu-item${
                  page === "/apropos" ? " selected" : ""
                }`}
                to="/apropos"
                onClick={() => setpage("/apropos")}
              >
                <h1>{menuTitles.about[language]}</h1>
              </Link>
              <Link
                className={`header-menu-item${
                  page === "/lapp" ? " selected" : ""
                }`}
                to="/lapp"
                onClick={() => setpage("/lapp")}
              >
                <h1>{menuTitles.app[language]}</h1>
              </Link>
              <Link
                className={`header-menu-item${
                  page === "/club" ? " selected" : ""
                }`}
                to="/club"
                onClick={() => setpage("/club")}
              >
                <h1>{menuTitles.club[language]}</h1>
              </Link>
            </div>
          )}
        </div>
        <div
          className={`header-menu-container ${phoneMenuVisible ? "" : "close"}`}
        >
          <Link
            className="header-menu-item"
            to="/telecharger"
            onClick={() => setphoneMenuVisible(false)}
          >
            <h1>{menuTitles.download[language]}</h1>
          </Link>
          <Link
            className="header-menu-item"
            to="/contact"
            onClick={() => setphoneMenuVisible(false)}
          >
            <h1>{menuTitles.contact[language]}</h1>
          </Link>
          <Link
            className="header-menu-item"
            to="/apropos"
            onClick={() => setphoneMenuVisible(false)}
          >
            <h1>{menuTitles.about[language]}</h1>
          </Link>
          <Link
            className="header-menu-item"
            to="/lapp"
            onClick={() => setphoneMenuVisible(false)}
          >
            <h1>{menuTitles.app[language]}</h1>
          </Link>
          <Link
            className="header-menu-item"
            to="/club"
            onClick={() => setphoneMenuVisible(false)}
          >
            <h1>{menuTitles.club[language]}</h1>
          </Link>
          <div className="languages-main-container">
            {availableLanguages.map((v, i) => {
              return (
                <div
                  key={`language_item_${i}`}
                  className={`languages-item${
                    language === v.value ? " selected" : ""
                  }`}
                  onClick={() => {
                    setlanguage(v.value);
                    setphoneMenuVisible(false);
                  }}
                >
                  <h4>{v.title}</h4>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div id="main-container" className="App">
        <Suspense fallback={<div>Chargement...</div>}>
          <Routes>
            <Route
              path="/telecharger"
              element={
                <DownloadPage
                  isBetaActive={isBetaActive}
                  isFBAvailable={isFBAvailable}
                  language={language}
                />
              }
            ></Route>
            <Route
              path="/get-it"
              element={
                <HomePage
                  isBetaActive={isBetaActive}
                  isFBAvailable={isFBAvailable}
                  language={language}
                  getIT={true}
                  setpage={setpage}
                />
              }
            ></Route>
            <Route
              path="/apropos"
              element={
                <AboutPage isFBAvailable={isFBAvailable} language={language} />
              }
            ></Route>
            <Route
              path="/contact"
              element={
                <ContactPage
                  isFBAvailable={isFBAvailable}
                  language={language}
                />
              }
            ></Route>
            <Route
              path="/club"
              element={<ClubSchemePage language={language} />}
            ></Route>
            <Route
              path="/lapp"
              element={<TutorialPage language={language} />}
            ></Route>
            <Route
              path="/admin"
              element={<AdminPage language={language} />}
            ></Route>
            <Route path="/admin/map" element={<AdminMap />}></Route>
            <Route
              path="/mentions-legales"
              element={<TermsPage language={language} />}
            ></Route>
            <Route
              path="/activities/:activity"
              element={<ActivityRedirectPage language={language} />}
            ></Route>
            <Route
              path="/join-group/:groupId"
              element={<JoinGroupPage language={language} />}
            ></Route>
            <Route
              path="/verification-email/:currentUserId/:verificationToken"
              element={<VerifyEmail language={language} />}
            ></Route>
            {inConstruction && (
              <Route
                path="/accueil"
                element={
                  <HomePage
                    isBetaActive={isBetaActive}
                    isFBAvailable={isFBAvailable}
                    language={language}
                    setpage={setpage}
                  />
                }
              ></Route>
            )}
            <Route
              path="/:activity"
              element={<ActivityRedirectPage language={language} />}
            ></Route>
            <Route
              path="/users/:user"
              element={<UserRedirectPage language={language} path={"users"} />}
            ></Route>
            <Route
              path="/clubs/:user"
              element={<UserRedirectPage language={language} path={"clubs"} />}
            ></Route>
            {inConstruction && (
              <Route
                path="/"
                element={<ConstructionPage language={language} />}
              ></Route>
            )}
            {!inConstruction && (
              <Route
                path="/"
                element={
                  <HomePage
                    isBetaActive={isBetaActive}
                    isFBAvailable={isFBAvailable}
                    language={language}
                    setpage={setpage}
                  />
                }
              ></Route>
            )}
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
